import React, {useState} from 'react';
import clsx from 'clsx';

const Select = ({
  options,
  className,
  radius = 'rounded-full',
  placeholder = '',
  padding = 'pl-4 pr-8',
  name,
  register,
  required = false,
  validate = {},
  error = false,
  onChange = false
}) => {
  const [selected, setSelected] = useState(placeholder)
  className = clsx('appearance-none cursor-pointer', className, radius, padding, {
    'text-[#9ca3af]': selected === placeholder
  }, error && 'bg-red-200');
  const onChangeCallback = onChange ? (e) => {
    onChange(name, e.currentTarget);
    setSelected(e.currentTarget.value)
  }
    : (e) => {setSelected(e.currentTarget.value)};
  return (
    <label className="relative after:absolute after:content-['<>'] after:right-2 after:text-[#9ca3af] after:border-b after:-top-0.5 after:rotate-90">
      <select className={className}
        {...register(name, {
          required: required,
          validate: validate
        })}
        onChange={onChangeCallback}>
        {placeholder.length &&
          <option className="text-[#9ca3af]">{placeholder}</option>}
        {options.map((i,k) => (
          <option key={k} value={i.value} className="text-black">{i.label}</option>
        ))}
      </select>
      </label>
  )
}

export default Select;