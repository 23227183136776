import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import {Link} from 'gatsby';
import {useContactInfo} from '../../utils/getContactInfo';

const ShortFooter = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  return (
    <footer className="bg-black pb-14 pt-20">
      <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-4">
        <div className="col-span-2 text-center mb-40">
          <StaticImage src="../../images/sourci_logo_white.png"
            alt="Sourci Logo"
            placeholder="blurred"
            width={180}
            className="mb-8" />
          <p className="text-white text-sm mb-4">© {year} Sourci - {useContactInfo().getSingular()}'s leading sourcing solution company. All Rights Reserved. <Link to="/" className="underline">Privacy Policy</Link></p>
          <p className="text-white text-sm">HQ: {useContactInfo().getAddress(1)} {useContactInfo().getAddress(2)} {useContactInfo().getAddress(3)}</p>
        </div>
      </div>
    </footer>
  )
}

export default ShortFooter;