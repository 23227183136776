import React from 'react';
import clsx from 'clsx';

const Textarea = ({
  className,
  placeholder,
  radius = 'rounded-3xl',
  padding = 'py-2.5 px-4',
  rows="3",
  register,
  name,
  required = false,
  error = false
}) => {
  className = clsx(className, radius, padding, error && 'bg-red-200');
  return (
    <textarea className={className}
      placeholder={placeholder}
      rows={rows}
      {...register(name, {
        required: required
      })}></textarea>
  );
}

export default Textarea;