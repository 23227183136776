import React from 'react';
import {Link} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from '../ContactForm';
import {useContactInfo} from '../../utils/getContactInfo';

const Footer = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  return (
    <footer className="bg-black pb-14 pt-20">
      <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
        <div className="text-white">
          <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
          <p className="text-sourci-pink text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
          <p className="text-3xl mb-2">
            <a href="tel:+611300768724"
              className="hover:underline">{useContactInfo().getContactNumber()}</a>
          </p>
          <p className="text-3xl mb-2">
            <a href={`mailto:${useContactInfo().getEmailAddress()}`}
              className="hover:underline">{useContactInfo().getEmailAddress()}</a>
          </p>
        </div>
        <div className="mt-12 lg:mt-0">
          <ContactForm />
        </div>
        <div className='col-span-2 text-center mt-28'>
          <StaticImage src="../../images/sourci_logo_white.png"
            alt="Sourci Logo"
            placeholder="blurred"
            width={180}
            className="mb-8" />
          <p className="text-white text-sm mb-4">© {year} Sourci - {useContactInfo().getSingular()}'s leading sourcing solution company. All Rights Reserved. <Link to="/privacy-policy" className="underline">Privacy Policy</Link></p>
          <p className="text-white text-sm">HQ: {useContactInfo().getAddress(1)} {useContactInfo().getAddress(2)} {useContactInfo().getAddress(3)}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;