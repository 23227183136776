import React from 'react';
import clsx from 'clsx';

const Input = ({
  type = 'text',
  radius = 'rounded-full',
  placeholder = '',
  padding = 'py-2.5 px-4',
  className,
  register,
  name,
  required,
  pattern = undefined,
  error = false
}) => {
  className = clsx(className, radius, padding, error && 'bg-red-200');
  return (
    <input type={type}
      className={className}
      placeholder={placeholder}
      {...register(name, {
        required: required,
        pattern: pattern
      })} />
  )
}

export default Input;