/*eslint no-throw-literal: 0*/

import { navigate } from 'gatsby';
import { useGatsbyEnv } from './use-gatsby-env';
import { useState } from 'react'

const makeUri = (portalId, formId) => {
  return `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`
}

export const useHsSubmit = (portalId, formId, location) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const env = useGatsbyEnv();
  const submit = (data) => {
    const hstracker = document.cookie.replace(
      /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
      '$1'
    )
    if(env === 'development') {
      console.log({
        fields: Object.keys(data).map((key) => ({ name: key, value: data[key] })),
        context: {
          pageName: location,
          pageUri: document.location.href,
          hutk: hstracker.length === 0 ? undefined : hstracker
        }
      });
      navigate('/thankyou');
      return;
    }

    fetch(makeUri(portalId, formId), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fields: Object.keys(data).map((key) => ({name: key, value: data[key]})),
        context: {
          pageName: location,
          pageUri: document.location.href,
          hutk: hstracker.length === 0 ? undefined : hstracker
        }
      })
    })
      .then(response=>response.json())
      .then(data => {
        if(data.redirectUri || false) {
          window.location.replace(data.redirectUri);
          return;
        }
        else {
          setErrorMessage("We're having trouble submitting your form. Please email hello@sourci.com.au to enquire further.")
        }
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("Something went wrong. Please email hello@sourci.com.au to enquire further.");
      })
  }

  return [errorMessage, submit];
}