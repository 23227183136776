import React from "react";
import {Link} from 'gatsby';
import Instagram from "../icons/Instagram";
import Facebook from "../icons/Facebook";
import LinkedIn from "../icons/LinkedIn";
import {useContactInfo} from '../../utils/getContactInfo';

const LandingFooter = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  return (
    <footer className="bg-black pb-14 pt-20">
      <div className="xl:container lg:grid lg:grid-cols-3 mx-auto px-4 text-white">
        <div className="lg:col-span-3">
          <p className="text-4xl md:text-6xl font-black mb-16">Let's talk.</p>
        </div>
        <div className="mb-12 lg:mb-0">
          <p className="text-3xl mb-2">
            <a href="tel:+611300768724"
              className="hover:underline">1300 SOURCI</a>
          </p>
          <p className="text-3xl mb-2">
            <a href="mailto:hello@sourci.com.au"
              className="hover:underline">hello@sourci.com.au</a>
          </p>
        </div>
        <div className="mb-12 lg:mb-0">
          <p className="font-bold text-2xl lg:text-3xl mb-2">Follow</p>
          <a className="text-2xl mb-2 flex hover:underline"
            href="https://www.instagram.com/sourci.co/"
            target="_blank"
            rel="noreferrer">
            <Instagram className="w-6 inline-block fill-white mr-2" />
            <span>Instagram</span>
          </a>
          <a className="text-2xl mb-2 flex hover:underline"
            href="https://www.facebook.com/sourci.co/"
            target="_blank"
            rel="noreferrer">
            <Facebook className="w-6 inline-block fill-white mr-2" />
            <span>Facebook</span>
          </a>
          <a className="text-2xl flex hover:underline"
            href="https://www.linkedin.com/company/sourci/"
            target="_blank"
            rel="noreferrer">
            <LinkedIn className="w-6 inline-block fill-white mr-2" />
            <span>LinkedIn</span>
          </a>
        </div>
        <div>
          <p className="font-bold text-2xl lg:text-3xl mb-2">Head Office</p>
          <a href={useContactInfo().getMapLink()}
            className="hover:underline">
            <p className="text-2xl mb-1">{useContactInfo().getAddress(1)}</p>
            <p className="text-2xl mb-1">{useContactInfo().getAddress(2)}</p>
            <p className="text-2xl">{useContactInfo().getAddress(3)}</p>
          </a>
        </div>
        <div className='lg:col-span-3 text-center mt-28'>
          <p className="text-white text-sm mb-4">© {year} Sourci - {useContactInfo().getSingular()}'s leading sourcing solution company. All Rights Reserved. <Link to="/" className="underline">Privacy Policy</Link></p>
          <p className="text-white text-sm">HQ: {useContactInfo().getAddress(1)} {useContactInfo().getAddress(2)} {useContactInfo().getAddress(3)}</p>
        </div>
      </div>
    </footer>
  )
}

export default LandingFooter;