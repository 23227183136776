import React, {useEffect} from 'react';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import ShortFooter from './layout/ShortFooter';
import LandingFooter from './layout/LandingFooter';
import { globalHistory } from '@reach/router'
import { GTMBody } from './layout/GoogleTags';

const getPageType = (path) => {
  const parts = path.split('/');
  switch (parts[1]) {
    case 'contact':
      return 'contact';
    case 'projects':
      return parts[2]?.length > 1 ? 'project' : 'default';
    case 'lp':
    case 'landing':
      return 'landing';
    case 'partners':
      return parts[2]?.length > 1 ? 'custom-landing' : 'default';
    case '':
      return 'homepage';
    default:
      return 'default';
  }
}

const FooterComponent = ({type}) => {
  switch (type) {
    case 'contact':
    case 'custom-landing':
      return <ShortFooter />
    case 'landing':
    case 'project':
      return <LandingFooter />
    case 'homepage':
      return <LandingFooter />
    default:
      return <Footer />
  }
}

const Layout = ({path, children}) => {
  const pageType = getPageType(path);
  useEffect(() => {
    const handleRouteChangeComplete = ({location}) => {
      if (!window._hsq) return
      window._hsq.push(['setPath', location.pathname])
      window._hsq.push(['trackPageView'])
    }

    const unregister = globalHistory.listen(handleRouteChangeComplete);

    return () => {
      unregister();
    }
  }, [])
  return (
    <React.StrictMode>
      <GTMBody />
      {(pageType !== 'landing' && pageType !== 'custom-landing') && <Navbar />}
      {children}
      <FooterComponent type={pageType} />
    </React.StrictMode>
  );
}

export default Layout;